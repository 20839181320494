<template>
    <div class="companymanage">
        <h1 class="page_title">{{ $route.meta.title }}</h1>
        <a-row>
            <a-col :span="4"></a-col>
            <a-col :span="4" offset="16" style="text-align: right">
                <a-button type="primary" @click="addCompany">
                    + 新增公司
                </a-button>
            </a-col>
        </a-row>
        <a-table
            rowKey="id"
            :columns="table_col"
            :data-source="table_data"
            :pagination="table_pagination"
            :loading="table_loading"
            @change="tableChange"
            style="margin-top: 16px"
        >
            <template slot="openApiStatus" slot-scope="text">
                <span>{{ !text ? "禁用" : "启用" }}</span>
            </template>
            <template slot="active" slot-scope="text, record">
                <a
                    style="margin-right: 8px"
                    href="javascript:;"
                    @click="editCompany(record)"
                >
                    编辑
                </a>
                <a-popconfirm
                    :title="`确认${
                        !record.openApiStatus ? '启用' : '禁用'
                    }token生成权限？`"
                    @confirm="handleTokenConfirm(record)"
                >
                    <a href="javaScript:;"
                        >{{ !record.openApiStatus ? "启用" : "禁用" }}token</a
                    >
                </a-popconfirm>
            </template>
        </a-table>
        <a-modal
            :visible="modal.visible && true"
            :title="modal.visible === 'add' ? '公司新增' : '公司编辑'"
            :confirmLoading="modal.loading"
            @cancel="cancelModal"
            @ok="okModal"
        >
            <a-form
                :form="modal.form"
                :label-col="{ span: 7 }"
                :wrapper-col="{ span: 12 }"
            >
                <a-form-item label="公司简称">
                    <a-input
                        :disabled="modal.visible === 'edit'"
                        placeholder="请输入公司简称"
                        v-decorator="[
                            'companyName',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入公司简称',
                                    },
                                ],
                                initialValue: modal.info.companyName,
                            },
                        ]"
                    />
                </a-form-item>
                <a-form-item label="公司全称">
                    <a-input
                        :disabled="modal.visible === 'edit'"
                        placeholder="请输入公司全称"
                        v-decorator="[
                            'fullName',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入公司全称',
                                    },
                                ],
                                initialValue: modal.info.fullName,
                            },
                        ]"
                    />
                </a-form-item>
                <a-form-item label="联系人">
                    <a-input
                        placeholder="请输入联系人"
                        v-decorator="[
                            'name',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入姓名',
                                    },
                                ],
                                initialValue: modal.info.name,
                            },
                        ]"
                    />
                </a-form-item>
                <a-form-item label="职务">
                    <a-input
                        placeholder="请输入职务"
                        v-decorator="[
                            'office',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入职务',
                                    },
                                ],
                                initialValue: modal.info.office,
                            },
                        ]"
                    />
                </a-form-item>
                <a-form-item label="联系电话">
                    <a-input
                        placeholder="请输入联系电话"
                        v-decorator="[
                            'phone',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入正确的手机号',
                                        pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/,
                                    },
                                ],
                                initialValue: modal.info.phone,
                            },
                        ]"
                    />
                </a-form-item>
                <a-form-item label="主管理员账号">
                    <a-input
                        :disabled="modal.visible === 'edit'"
                        placeholder="4-10位中文、英文、数字"
                        v-decorator="[
                            'userName',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: '4-10位中文、英文、数字',
                                        pattern:
                                            /^[\u4e00-\u9fa5a-zA-Z0-9]{4,10}$/,
                                    },
                                ],
                                initialValue: modal.info.userName,
                            },
                        ]"
                    />
                </a-form-item>
                <a-form-item label="密码" v-if="modal.visible === 'add'">
                    <a-input
                        placeholder="8-20位大小写字母和数字组合"
                        v-decorator="[
                            'password',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: '8-20位大小写字母和数字组合',
                                        pattern:
                                            /(?=\S*\d)(?=\S*[a-z])(?=\S*[A-Z])^[\S]{8,20}$/,
                                    },
                                ],
                                initialValue: modal.info.password,
                            },
                        ]"
                    />
                </a-form-item>
                <a-form-item label="子域名">
                    <a-input
                        :disabled="modal.visible === 'edit'"
                        placeholder="请输入子域名"
                        v-decorator="[
                            'subDomainName',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入子域名',
                                    },
                                ],
                                initialValue: modal.info.subDomainName,
                            },
                        ]"
                    />
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
import {
    companyPage,
    companyCreate,
    companyAdminInfo,
    companyUpdate,
    openApiEnalbe,
    openApiDisalbe,
} from "@/api/index";
export default {
    name: "CompanyManage",
    data() {
        return {
            table_col: [
                {
                    title: "序号",
                    dataIndex: "key",
                    customRender: (text, record, index) => {
                        return (
                            (this.table_pagination.current - 1) *
                                this.table_pagination.pageSize +
                            (index + 1)
                        );
                    },
                },
                { title: "公司简称", dataIndex: "name" },
                { title: "公司全称", dataIndex: "fullName" },
                { title: "主管理员账号", dataIndex: "userName" },
                { title: "创建时间", dataIndex: "createTime" },
                {
                    title: "token生成状态",
                    dataIndex: "openApiStatus",
                    scopedSlots: { customRender: "openApiStatus" },
                },
                {
                    title: "操作",
                    dataIndex: "active",
                    scopedSlots: { customRender: "active" },
                },
            ],
            modal: {
                form: this.$form.createForm(this),
                modal: false,
                info: {},
                loading: false,
            },
        };
    },
    created() {},
    mounted() {
        this.getTableList();
    },

    methods: {
        //token操作
        handleTokenConfirm(record) {
            let fn = !record.openApiStatus ? openApiEnalbe : openApiDisalbe;
            fn({ id: record.id }).then((res) => {
                if (res?.data) {
                    this.$message.success("操作成功");
                    this.getTableList();
                } else {
                    this.$message.success("操作失败");
                }
            });
        },
        // 公司列表
        getTableList() {
            this.table_loading = true;

            companyPage({
                current: this.table_pagination.current,
                pageSize: this.table_pagination.pageSize,
            })
                .then((res) => {
                    this.table_data = res.data.list;
                    this.table_pagination.total = res.data.total;
                })
                .catch(() => (this.table_data = []))
                .finally(() => (this.table_loading = false));
        },

        // 新增公司
        addCompany() {
            this.modal.info = {};
            this.modal.visible = "add";
            this.$nextTick(() => this.modal.form.resetFields());
        },
        // 编辑公司
        editCompany(record) {
            console.log(record);

            let companyInfo = {
                id: record.id,
                fullName: record.fullName,
                companyName: record.name,
                userName: record.userName,
                subDomainName: record.subDomainName,
            };
            companyAdminInfo({ companyId: record.id }).then((res) => {
                this.modal.info = {
                    ...companyInfo,
                    userId: res.data.id,
                    name: res.data.name,
                    office: res.data.office,
                    phone: res.data.phone,
                };
                this.modal.visible = "edit";
            });
        },
        // 取消新增
        cancelModal() {
            this.modal.info = {};
            this.modal.loading = false;
            this.modal.form.resetFields();
            this.modal.visible = false;
        },
        // 确定新增、编辑
        okModal() {
            let fieldNames =
                this.modal.visible == "edit"
                    ? ["name", "phone", "office"]
                    : null;
            this.modal.form.validateFields(fieldNames, (err, values) => {
                if (!err) {
                    this.modal.loading = true;

                    let data = {};
                    console.log(values);

                    if (this.modal.visible == "add") {
                        data = {
                            ...values,
                        };
                        console.log(data);
                        companyCreate(data).then(() => {
                            this.$message.success("新增成功");
                            this.getTableList();
                            this.cancelModal();
                        });
                    } else {
                        data = {
                            userId: this.modal.info.userId,
                            name: values.name,
                            phone: values.phone,
                            office: values.office,
                        };
                        console.log(data);
                        companyUpdate(data).then(() => {
                            this.$message.success("修改成功");
                            this.getTableList();
                            this.cancelModal();
                        });
                    }
                }
            });
        },
    },
};
</script>

<style scoped lang="less">
.companymanage {
    &-item {
        display: flex;
        align-items: center;
        &-wrapper {
            flex: 1;
            .ant-select {
                width: 100%;
            }
        }
    }
}
</style>
