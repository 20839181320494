var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"companymanage"},[_c('h1',{staticClass:"page_title"},[_vm._v(_vm._s(_vm.$route.meta.title))]),_c('a-row',[_c('a-col',{attrs:{"span":4}}),_c('a-col',{staticStyle:{"text-align":"right"},attrs:{"span":4,"offset":"16"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.addCompany}},[_vm._v(" + 新增公司 ")])],1)],1),_c('a-table',{staticStyle:{"margin-top":"16px"},attrs:{"rowKey":"id","columns":_vm.table_col,"data-source":_vm.table_data,"pagination":_vm.table_pagination,"loading":_vm.table_loading},on:{"change":_vm.tableChange},scopedSlots:_vm._u([{key:"openApiStatus",fn:function(text){return [_c('span',[_vm._v(_vm._s(!text ? "禁用" : "启用"))])]}},{key:"active",fn:function(text, record){return [_c('a',{staticStyle:{"margin-right":"8px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.editCompany(record)}}},[_vm._v(" 编辑 ")]),_c('a-popconfirm',{attrs:{"title":("确认" + (!record.openApiStatus ? '启用' : '禁用') + "token生成权限？")},on:{"confirm":function($event){return _vm.handleTokenConfirm(record)}}},[_c('a',{attrs:{"href":"javaScript:;"}},[_vm._v(_vm._s(!record.openApiStatus ? "启用" : "禁用")+"token")])])]}}])}),_c('a-modal',{attrs:{"visible":_vm.modal.visible && true,"title":_vm.modal.visible === 'add' ? '公司新增' : '公司编辑',"confirmLoading":_vm.modal.loading},on:{"cancel":_vm.cancelModal,"ok":_vm.okModal}},[_c('a-form',{attrs:{"form":_vm.modal.form,"label-col":{ span: 7 },"wrapper-col":{ span: 12 }}},[_c('a-form-item',{attrs:{"label":"公司简称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'companyName',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入公司简称',
                                } ],
                            initialValue: _vm.modal.info.companyName,
                        } ]),expression:"[\n                        'companyName',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请输入公司简称',\n                                },\n                            ],\n                            initialValue: modal.info.companyName,\n                        },\n                    ]"}],attrs:{"disabled":_vm.modal.visible === 'edit',"placeholder":"请输入公司简称"}})],1),_c('a-form-item',{attrs:{"label":"公司全称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'fullName',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入公司全称',
                                } ],
                            initialValue: _vm.modal.info.fullName,
                        } ]),expression:"[\n                        'fullName',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请输入公司全称',\n                                },\n                            ],\n                            initialValue: modal.info.fullName,\n                        },\n                    ]"}],attrs:{"disabled":_vm.modal.visible === 'edit',"placeholder":"请输入公司全称"}})],1),_c('a-form-item',{attrs:{"label":"联系人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'name',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入姓名',
                                } ],
                            initialValue: _vm.modal.info.name,
                        } ]),expression:"[\n                        'name',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请输入姓名',\n                                },\n                            ],\n                            initialValue: modal.info.name,\n                        },\n                    ]"}],attrs:{"placeholder":"请输入联系人"}})],1),_c('a-form-item',{attrs:{"label":"职务"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'office',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入职务',
                                } ],
                            initialValue: _vm.modal.info.office,
                        } ]),expression:"[\n                        'office',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请输入职务',\n                                },\n                            ],\n                            initialValue: modal.info.office,\n                        },\n                    ]"}],attrs:{"placeholder":"请输入职务"}})],1),_c('a-form-item',{attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'phone',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入正确的手机号',
                                    pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/,
                                } ],
                            initialValue: _vm.modal.info.phone,
                        } ]),expression:"[\n                        'phone',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请输入正确的手机号',\n                                    pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/,\n                                },\n                            ],\n                            initialValue: modal.info.phone,\n                        },\n                    ]"}],attrs:{"placeholder":"请输入联系电话"}})],1),_c('a-form-item',{attrs:{"label":"主管理员账号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'userName',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: '4-10位中文、英文、数字',
                                    pattern:
                                        /^[\u4e00-\u9fa5a-zA-Z0-9]{4,10}$/,
                                } ],
                            initialValue: _vm.modal.info.userName,
                        } ]),expression:"[\n                        'userName',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '4-10位中文、英文、数字',\n                                    pattern:\n                                        /^[\\u4e00-\\u9fa5a-zA-Z0-9]{4,10}$/,\n                                },\n                            ],\n                            initialValue: modal.info.userName,\n                        },\n                    ]"}],attrs:{"disabled":_vm.modal.visible === 'edit',"placeholder":"4-10位中文、英文、数字"}})],1),(_vm.modal.visible === 'add')?_c('a-form-item',{attrs:{"label":"密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'password',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: '8-20位大小写字母和数字组合',
                                    pattern:
                                        /(?=\S*\d)(?=\S*[a-z])(?=\S*[A-Z])^[\S]{8,20}$/,
                                } ],
                            initialValue: _vm.modal.info.password,
                        } ]),expression:"[\n                        'password',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '8-20位大小写字母和数字组合',\n                                    pattern:\n                                        /(?=\\S*\\d)(?=\\S*[a-z])(?=\\S*[A-Z])^[\\S]{8,20}$/,\n                                },\n                            ],\n                            initialValue: modal.info.password,\n                        },\n                    ]"}],attrs:{"placeholder":"8-20位大小写字母和数字组合"}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"子域名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'subDomainName',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入子域名',
                                } ],
                            initialValue: _vm.modal.info.subDomainName,
                        } ]),expression:"[\n                        'subDomainName',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请输入子域名',\n                                },\n                            ],\n                            initialValue: modal.info.subDomainName,\n                        },\n                    ]"}],attrs:{"disabled":_vm.modal.visible === 'edit',"placeholder":"请输入子域名"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }